.contact_hero {
    background: url('../../../public/assets/images/contact-us/contact-us-page-hero.png');
    height: 497px;
    background-position: center;
    background-size: cover;
}

@media (max-width: 768px) {
    .contact_hero {
        background: url('../../../public/assets/images/contact-us/contact-us-page-hero-mobile.png');
        height: 250px;
        background-position: center;
        background-size: cover;
    }
}

@media (min-width: 1920px) {

    .contact_hero {
        height: 670px;
    }


}

.contact-form-card-shadow {
    box-shadow: 0px 0px 36px -1px rgba(226, 214, 230, 0.75);
    -webkit-box-shadow: 0px 0px 36px -1px rgba(226, 214, 230, 0.75);
    -moz-box-shadow: 0px 0px 36px -1px rgba(226, 214, 230, 0.75);
}


.contact-form-shadow-2 {
    box-shadow: -4px -2px 0px -4px rgba(0, 0, 0, 0.84);
    -webkit-box-shadow: -4px -2px 0px -4px rgba(0, 0, 0, 0.84);
    -moz-box-shadow: -4px 4px 0px -4px rgba(0, 0, 0, 0.84);
    transition: .5s all;
}

.contact-form-shadow-2:hover {
    box-shadow: -14px -14px 0px -4px rgba(0, 0, 0, 0.84);
    -webkit-box-shadow: -14px -14px 0px -4px rgba(0, 0, 0, 0.84);
    -moz-box-shadow: -14px -14px 0px -4px rgba(0, 0, 0, 0.84);
    border-radius: 10%;
    transition: .5s all;
}