@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

.main_hero {
    background: url('../../../public/assets/images/home-page/hero-section/home-page-hero-bg.png') no-repeat center center;
    background-size: cover;
    height: 700px;
}

@media (max-width: 768px) {
    .main_hero {
        background: url('../../../public/assets/images/home-page/hero-section/hero-mobile-1.png') no-repeat center center;
        height: 764px;
    }
}

@media (min-width: 1920px) {
    .main_hero {
        height: 900px;
    }
}

.avatar-group {
    display: flex;
    align-items: center;
}

.avatar img {
    border-radius: 50%;
}

.avatar-group img {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

/* HeroSection.css */

.helping-text {
    background-color: white; /* Background color */
    color: black;            /* Font color */
    padding: 4px 8px;       /* Padding for spacing around the text */
    display: inline-block;   /* Ensures background color wraps tightly around text */
    white-space: nowrap;     /* Prevents text wrapping */
    text-align: center;      /* Center text */
    font-size: 1rem;         /* Adjust font size as needed */
}

