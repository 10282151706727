.about_hero {
    background: url('../../../public/assets/images/about-us-page/about-us-page-hero.png');
    height: 460px;
    background-position: center;
    background-size: cover;
}

@media (max-width: 768px) {
    .about_hero {
        background: url('../../../public/assets/images/about-us-page/about-us-page-hero-mobile.png');
        height: 250px;
        background-position: center;
        background-size: cover;
    }
}

@media (min-width: 1920px) {
    .about_hero {
        height: 600px;
    }

    .custom_about_img {
        width: 726px;
        height: 631px;
    }

    .custom_about_heading {
        font-size: 35px;
    }

    .custom_about_sub_heading {
        font-size: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 45px;
    }

    .custom_about_para {
        font-size: 22px;
        line-height: 30px;
    }
}