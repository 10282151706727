.member-verification_hero {
    background: url('../../../public/assets/images/member-verification/member-verification-page-hero.png');
    height: 497px;
    background-position: center;
    background-size: cover;
}

@media (max-width: 768px) {
    .member-verification_hero {
        background: url('../../../public/assets/images/member-verification/member-verification-page-mobile-hero.png');
        height: 250px;
        background-position: center;
        background-size: cover;
    }
}

@media (min-width: 1920px) {
    .member-verification_hero {
        height: 600px;
    }
}