.main_support {
    background: url('../../../../public/assets/images/home-page/support-section/homepage-support-bg.png');
    height: auto; /* Adjust height as needed */
    padding-bottom: 40px; /* Add padding at the bottom */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .main_support {
        background: url('../../../../public/assets/images/home-page/support-section/support-mobile-bg.png');
        background-size: cover;
        background-position: center;
        height: auto; /* Adjust height as needed */
        padding-bottom: 40px; /* Maintain bottom padding */
        background-repeat: no-repeat;
    }

    .support-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns layout */
        gap: 16px; /* Space between cards */
    }
}

/* Styles for large screens (1920px and above) */
@media (min-width: 1920px) {
    .main_support {
        height: auto; /* Adjust height as needed */
        padding-bottom: 40px; /* Maintain bottom padding */
    }
}