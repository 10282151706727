.become-a-member_hero {
    background: url('../../../public/assets/images/become-a-member/become-a-member-hero-bg.png');
    height: 497px;
    background-position: center;
    background-size: cover;
}

@media (max-width: 768px) {
    .become-a-member_hero {
        background: url('../../../public/assets/images/become-a-member/become-a-member-hero-mobile.png');
        height: 250px;
        background-position: center;
        background-size: cover;
    }
}

@media (min-width: 1920px) {
    .become-a-member_hero {
        height: 600px;
    }
}