@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap'); */

* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.cabinet_image_shadow {
    box-shadow: -2px -2px 0px -4px rgba(0, 0, 0, 0.84);
    -webkit-box-shadow: -2px -2px 0px -4px rgba(0, 0, 0, 0.84);
    -moz-box-shadow: -4px 4px 0px -4px rgba(0, 0, 0, 0.84);
    transition: .5s all;
}

.cabinet_image_shadow:hover {
    box-shadow: -14px -14px 0px -4px rgba(0, 0, 0, 0.84);
    -webkit-box-shadow: -14px -14px 0px -4px rgba(0, 0, 0, 0.84);
    -moz-box-shadow: -14px -14px 0px -4px rgba(0, 0, 0, 0.84);
    border-radius: 5%;
    transition: .5s all;
}

.mobile_footer {
    font-family: 'Montserrat', sans-serif;
}

.mobile_footer p {
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 768px) {
    .login-page-hero {
        background: url('../public/assets/images/login-hero-mobile-bg.png');
        height: 250px;
        background-position: center;
        background-size: cover;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Universal font sizes for all devices */
body {
    font-size: 1.1rem !important; /* Base font size */
    font-family: 'Montserrat', sans-serif !important; /* Set Montserrat as the default font */
    line-height: 1.6 !important; /* Improve readability */
    color: #333 !important; /* Default text color */
}

h1 {
    font-size: 2rem !important; /* Large heading */
    font-weight: 700 !important; /* Bold for headings */
}

h2 {
    font-size: 1.75rem !important; /* Subheading */
    font-weight: 600 !important;
}

h3 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
}

h4 {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
}

h5, h6 {
    font-size: 1rem !important;
    font-weight: 500 !important;
}

p {
    font-size: 1.1rem !important; /* Default paragraph size */
    line-height: 1.6 !important; /* Improve readability */

    font-weight: 400 !important; /* Regular weight for paragraphs */
}

a {
    font-size: 1.1rem !important; /* Default paragraph size */
    font-weight: 400 !important; /* Regular weight for paragraphs */
    line-height: 1.6 !important; /* Improve readability */

}

li, ul {
    font-size: 1.1rem !important; /* Default paragraph size */
    line-height: 1.6 !important; /* Improve readability */

    font-weight: 400 !important; /* Regular weight for paragraphs */
}


/* Specific font sizes for mobile devices */
@media (max-width: 768px) {
    h1 {
        font-size: 1.5rem !important;
    }
    
    h2 {
        font-size: 1.25rem !important;
    }
    
    h3 {
        font-size: 1rem !important;
    }
    
    h4, h5, h6 {
        font-size: 0.875rem !important;
    }

    p {
        font-size: 0.9rem !important; /* Default paragraph size */
    }
    
    a {
        font-size: 0.9rem !important; /* Default paragraph size */
    }

    li {
        font-size: 0.9rem !important; /* Default paragraph size */
    }
    
 }

