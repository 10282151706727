.main_help {
    background: url('../../../../public/assets/images/home-page/help-section/home-page-help-bg.png');
    min-height: 570px;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 768px) {
    .main_help {
        background: url('../../../../public/assets/images/home-page/help-section/home-page-help-bg-mobile.png');
        min-height: 580px;
        background-position: center;
        background-size: cover;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px; /* Adjust the gap as needed */
        justify-content: center; /* Center align the grid items */
        align-items: center; /* Align items vertically centered */
    }

    .mobile-btn {
        padding: 0.5rem 1rem; /* Reduce padding */
        font-size: 0.875rem; /* Adjust font size */
        width: 100%; /* Ensure it fits within the card */
    }
}

/* Ensure the container maintains the grid layout on larger screens */
@media (min-width: 769px) {
    .grid-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;
    }
}
